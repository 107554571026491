<template>
  <div class="pt-2 mr-2 column is-3" style="border-right: 1px solid #dedede;">
    <!--Truck prefix-->
    <div class="parent has-text-info-dark">
      <div class="child1">
        <small class="help ml-2 mt-0">
          <i v-if="!$parent.$parent.truck_prefix" class="fas fa-exclamation-triangle is-small has-text-danger"></i>
          {{ $t("truck_name") }}
          <span v-if="!$parent.$parent.truck_prefix">
            <br>{{ $t("required") }}
          </span>
          <div class="has-text-danger"
            v-if="$parent.$parent.truck_prefix && ($parent.$parent.truck_prefix == 'TRUCKNAME' || $parent.$parent.truck_prefix == '(TRUCK NAME)')">
            <i class="fa fa-exclamation-triangle"></i>
            TRUCK NAME MISSING
          </div>
        </small>
      </div>
      <div class="child2">
        <div v-if="am_allowed('parameter.can_edit')" class="field has-addons">
          <div class="control">
            <input v-model="$parent.$parent.truck_prefix" class="input is-small"
              :class="{ 'is-danger': !$parent.$parent.truck_prefix }" type="text" :placeholder="$t('add_truck_name')">
          </div>
          <div class="control">
            <a @click.prevent="$parent.save_truck_prefix('prefix')" class="button is-success is-small">
              <i class="fas fa-truck"></i>
            </a>
          </div>
        </div>
        <div v-else class="is-flex is-justify-content-center">
          {{ $parent.$parent.truck_prefix }}
        </div>
      </div>
    </div>
    <div class="mb-3 pt-1 border-bottom-orange-1">
      <span class="is-size-6 has-text-info-dark">{{ $t("general_information") }}</span>
    </div>
    <!-- <pre>{{$parent.$parent.event_details}}</pre> -->
    <div class="columns" style="border-bottom: 1px solid #dedede;">
      <div class="column is-6 py-1">
        <span class="general_title"> {{ $t("version_nr") }} </span>
      </div>
      <div v-if="am_allowed('parameter.can_edit')" class="column is-5 general_title has-text-dark">
        <input v-model="$parent.$parent.working_version" type="number" class="input mr-2 is-small">
      </div>
      <div v-if="am_allowed('parameter.can_edit')" class="column p-0 mt-1">
        <a :disabled="$parent.$parent.working_version == ''" @click.prevent="$parent.save_truck_prefix('version')"
          href="">
          <i class="has-text-success fas fa-check-circle"></i>
        </a>
      </div>
      <div v-if="!am_allowed('parameter.can_edit')" class="column py-1">
        <span class="has-text-black">
          {{ $parent.$parent.working_version }}
        </span>
      </div>
    </div>
    <div class="columns" style="border-bottom: 1px solid #dedede;">
      <div class="column is-6 general_title">
        <span>
          {{ $t("Easyjob_project_number") }}
        </span>
      </div>
      <div class="column py-1 has-text-dark t_size">{{ $parent.$parent.event_details.Number }}</div>
    </div>
    <div class="columns" style="border-bottom: 1px solid #dedede;">
      <div class="column is-6 py-0">
        <span class="general_title">
          {{ $t("start_date") }}
        </span>
      </div>
      <div class="column py-1 has-text-dark t_size">{{ $parent.$parent.event_details.StartDate | customdate }}</div>
    </div>
    <div class="columns" style="border-bottom: 1px solid #dedede;">
      <div class="column is-6 py-0">
        <span class="general_title">
          {{ $t("end_date") }}
        </span>
      </div>
      <div class="column py-1 has-text-dark t_size">{{ $parent.$parent.event_details.EndDate | customdate }}</div>
    </div>
    <div class="columns" style="border-bottom: 1px solid #dedede;">
      <div class="column is-6 py-0">
        <span class="general_title">
          {{ $t("show_dates") }}
        </span>
      </div>
      <div class="column py-1 has-text-dark t_size">{{ $parent.$parent.event_details.Number }}</div>
    </div>
    <div class="columns mb-1" style="border-bottom: 1px solid #dedede;">
      <div class="column py-0 is-6">
        <span class="general_title">
          {{ $t("project_manager") }}
        </span>
      </div>
      <div class="column py-1 has-text-dark t_size">{{
        $parent.$parent.event_details.Arranger ?
          $parent.$parent.event_details.Arranger.DisplayName : ''
      }}</div>
    </div>
    <div class="is-flex border-bottom-green-2 mb-1 is-flex-direction-column">
      <span class="has-text-dark pl-2 m-0 m_size">{{ $t("delivery_adress") }}</span>
    </div>
    <div class="columns mb-1" style="border-bottom: 1px solid #dedede;">
      <div class="column is-6 pb-1 has-text-dark">
        <div class="is-flex is-flex-direction-column">
          <div class="is-flex is-flex-direction-column">
            <span class="general_title">{{ $t("name") }}</span>
            <span class="t_size">{{
              $parent.$parent.event_details.Address_Customer ?
                $parent.$parent.event_details.Address_Customer.CompanyName : ''
            }}</span>
          </div>
          <div class="is-flex is-flex-direction-column">
            <span class="general_title">{{ $t("street_name") }}</span>
            <span class="t_size">{{
              $parent.$parent.event_details.Address_Customer ?
                $parent.$parent.event_details.Address_Customer.Street : ''
            }}</span>
          </div>
          <div class="is-flex is-flex-direction-column">
            <span class="general_title">Zip code/City</span>
            <span class="t_size">{{
              $parent.$parent.event_details.Address_Customer ?
                $parent.$parent.event_details.Address_Customer.Zip : ''
            }},
              {{
                $parent.$parent.event_details.Address_Customer ? $parent.$parent.event_details.Address_Customer.City :
                  ''
              }}</span>
          </div>

          <div class="is-flex is-flex-direction-column" v-if="$parent.$parent.event_details.Address_Customer">
            <span class="general_title">{{ $t("country") }}</span>
            <span class="t_size">{{
              $parent.$parent.event_details.Address_Customer.Country ?
                $parent.$parent.event_details.Address_Customer.Country.Caption : ''
            }}</span>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="is-flex is-flex-direction-column">
          <a v-if="am_allowed('parameter.can_create')"
            @click.prevent="$parent.open_modal(0, $parent.locations_list, 3, 'create')"
            class="has-text-dark is-pulled-left">
            <span class="mr-2 t_size">{{ $t("add_location") }}</span>
            <i class="has-text-dark fas fa-plus-circle"></i>
          </a>
        </div>
        <div v-for="gate in $parent.get_relative_parameters(0, 3)" :key="gate.id">
          <div class="tags has-addons mx-0 mb-1">
            <!-- Edit Gate -->
            <a class="tag is-small is-flex-grow-1 has-background-white mb-0"
              @click.prevent="$parent.open_modal(0, [], 3, 'edit', gate)">
              <span class="has-text-dark">{{ gate.name }}</span>
            </a>
            <!--add supervisor-->
            <a v-if="am_allowed('parameter.can_create')" @click.prevent="$parent.open_modal(gate.id, [], 4, 'create')"
              href="" class="tag is-small has-text-dark mb-0 parameter_add" :title="$t('add_supervisor')">
              <i class="fas fa-user-plus ml-1"></i>
            </a>
            <!-- Delete gate -->
            <a v-if="am_allowed('parameter.can_delete')" @click.prevent="$parent.remove_parameter(gate)"
              class="tag is-small parameter_delete mb-0" :title="$t('delete_location')">
              <i class="fa fa-trash-alt has-text-danger"></i>
            </a>
          </div>
          <!-- <br> -->
          <parameter-general-render v-for="(user, index) in $parent.get_relative_parameters(gate.id, 4)" :key="index"
            :relation="gate.id" :parameters_list="[]" :type="4" :form_mode="'edit'" :param='user'
            :index="index"></parameter-general-render>
        </div>
      </div>
    </div>
    <div class="is-flex border-bottom-blue-1 mb-1 is-flex-direction-column">
      <span class="has-text-dark pl-2 m-0 m_size">{{ $t("contact_on_site") }}</span>
    </div>
    <div class="columns mb-1" style="border-bottom: 1px solid #dedede;">
      <div class="column pb-1">
        <div class="is-flex ml-2 is-flex-direction-column">
          <a v-if="am_allowed('parameter.can_create')"
            @click.prevent="$parent.open_modal(0, $parent.ej_users_list, 5, 'create')"
            class="has-text-dark is-pulled-left">
            <span class="mr-2 t_size">{{ $t("add_contact") }}</span>
            <i class="has-text-dark fas fa-plus-circle"></i>
          </a>
        </div>
        <div class="mr-2">
          <parameter-general-render v-for="(user, index) in $parent.get_relative_parameters(0, 5)" :key="index"
            :relation="0" :parameters_list="$parent.ej_users_list" :type="5" :form_mode="'edit'" :param='user'
            :index="index"></parameter-general-render>
        </div>
      </div>
    </div>
    <div class="is-flex border-bottom-blue-2 mb-1 is-flex-direction-column">
      <span class="has-text-dark pl-2 m-0 m_size">{{ $t("comments") }}</span>
    </div>
    <div class="columns mb-1">
      <div class="column has-text-dark pb-1">
        <div class="mr-2 mb-1">
          <select v-model="$parent.$parent.note_priority" name="" id="" class="is-borderl">
            <option value="0">{{ $t("normal") }}</option>
            <option value="1">{{ $t("medium") }}</option>
            <option value="2">{{ $t("high") }}</option>
          </select>
        </div>
        <div class="is-flex">
          <textarea v-model="$parent.$parent.project_note" placeholder="Leave a comment..."
            class="input has-border mt-1 is-flex-grow-1" style="max-width: 100%;"></textarea>
          <a @click.prevent="$parent.$parent.createProjectNote()" class="button is-small mt-2"><i
              class="fas fa-check"></i></a>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="has-text-dark" style="background: rgb(231 247 183);">
          <p class="is-size- px-2 py-1">
            <span @click="show_project_notes = !show_project_notes" class="is-clickable">
              <span v-if="$parent.$parent.notes.length > 0">
                <i v-if="show_project_notes" class="fa fa-chevron-up"></i>
                <i v-else class="fa fa-chevron-down"></i>
              </span>
              <span class="ml-1" style="font-size:10px;">
                <small v-if="$parent.$parent.notes.length > 0">
                  {{ $parent.$parent.notes.length }}
                </small>
                <span class="is-uppercase">
                  Project related discussion
                </span>
              </span>
            </span>
          </p>
          <div v-if="show_project_notes" class="px-2">
            <div v-for="(note, index) in $parent.$parent.notes" :key="index" class="has-border-bottom-grey mb-2">
              <p><small class="general_title">{{ note.created_by_name }}</small> <small
                  class="general_title is-pulled-right">{{ note.created_at | customdate }}</small></p>
              <p class="has-text-dark">
                <span class="mr-2">
                  <i class="fa fa-comment normal_priority" v-if="note.note_type == 0" :title="$t('normal')"></i>
                  <i class="fa fa-comment medium_priority" v-else-if="note.note_type == 1" :title="$t('medium')"></i>
                  <i class="fa fa-comment high_priority" v-else-if="note.note_type == 2" :title="$t('high')"></i>
                </span>
                <small>{{ note.content }}</small>
                <a v-if="note.can_delete < 20 && note.created_by == user_id"
                  @click.prevent="$parent.$parent.delete_note(note.guid)" class="is-pulled-right">
                  <i class="fa fa-trash-alt fa-xs has-text-danger "></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permissionMixin from '@/permissions-mixin.js'
export default {
  mixins: [permissionMixin],
  data() {
    return {
      show_project_notes: false
    }
  },
  components: {
    'parameter-general-render': () => import('@/components/steps/Parameters/parameter-general-render.vue')
  },
  computed: {
    user_id() {
      var o = ''
      if (JSON.parse(localStorage.getItem("user"))) {
        o = JSON.parse(localStorage.getItem("user")).IdUser
      }
      return o
    },
  }

}
</script>

<style>
.parent {
  display: flex;
  flex-direction: row;
}

.child1 {
  width: 30%;
  text-align: center;
}

.child2 {
  width: 70%;
}

.help {
  font-size: 15px;
  font-weight: 600;
}

.general_title {
  color: #75868b;
  font-size: 13px;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.t_size {
  font-size: 14px;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.m_size {
  font-size: 15px;
}

.border-bottom-green-2 {
  border-bottom: solid 2px #56bca4;
}

.border-bottom-orange-1 {
  border-bottom: solid 2px #ddb03b;
}

.border-bottom-blue-1 {
  border-bottom: solid 2px #2494a2;
}

.border-bottom-blue-2 {
  border-bottom: solid 2px #9ad0e1;
}

.parameter_add {
  background: #e8fff8 !important;
}

.parameter_delete {
  background: #e3f1fc !important;
}
</style>
